import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';

import Bio from '../components/Bio';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import { rhythm, scale } from '../utils/typography';
import PageTitle from '../components/PageTitle';

const SmallContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

class PageTemplate extends React.Component {
  render() {
    const page = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const { previous, next } = this.props.pageContext;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={page.frontmatter.title} description={page.excerpt} />
        <SmallContent>
          <PageTitle title={page.frontmatter.title} />
          <div dangerouslySetInnerHTML={{ __html: page.html }} />
        </SmallContent>
      </Layout>
    );
  }
}

export default PageTemplate;

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
