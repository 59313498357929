import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import moment from 'moment';
import { InlineLink } from './Links';

import { rhythm } from '../utils/typography';

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social, bio } = data.site.siteMetadata;
        const age = moment().diff(bio.dateOfBirth, 'years');
        return (
          <div
            style={{
              display: `flex`,
              marginBottom: 40,
            }}
          >
            <Image
              fluid={data.avatar.childImageSharp.fluid}
              alt={author}
              style={{
                marginBottom: 0,
                minWidth: 100,
                alignSelf: `center`,
                borderRadius: `100%`,
                marginRight: 20,
              }}
              imgStyle={{
                borderRadius: `50%`,
              }}
            />
            <p style={{ marginBottom: 0 }}>
              <strong>{author}</strong> is a JavaScript-Developer from 📍
              {bio.city}. He is {age} years old and loves building beautiful
              things on the web 🚀! Do you want to learn more{' '}
              <InlineLink as={Link} to="/about">
                about him 👋
              </InlineLink>
              ? Or his{' '}
              <InlineLink as={Link} to="/work">
                work 👷
              </InlineLink>
              ?{` `}
              Or get in{' '}
              <InlineLink as={Link} to="/contact">
                touch 📫
              </InlineLink>
              ?
            </p>
          </div>
        );
      }}
    />
  );
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    site {
      siteMetadata {
        author
        bio {
          city
          dateOfBirth
        }
        social {
          twitter
        }
      }
    }
  }
`;

export default Bio;
